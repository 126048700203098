.bannerDetail {
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    height: 300px;
    background-size: contain;
}

.bannerSection .slick-slider.slick-initialized .slick-prev {
    left: 25px;
    z-index: 10;
}

.bannerSection .slick-slider.slick-initialized .slick-next {
    right: 25px;
    z-index: 10;
}

.bannerSection>div>div>div>div {
    margin: 0 !important;
}


.categoryItemSlider>.slick-initialized {
    height: 500px !important;
}


.bannerDetail:before {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #0e0e0e;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #171717, #ffffff00);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #171717, #ffffff00);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 1;
    content: "";
}

.bannerItem {
    position: relative;
}

.bannerText {
    position: absolute;
    top: 60%;
    transform: translateY(-60%);
    left: 0;
    margin: auto;
    right: 0;
    text-align: center;
    width: 50%;
}

.bannerText h6 {
    color: #f8f8f8;
}

.bannerText h2 {
    font-size: 50px;
    color: #fff;
}

.bannerText p {
    color: #fff;
}

.bannerContent .title h6 {
    color: #b1b1b1;
    text-transform: capitalize;
    display: none;
}

.bannerText a.btn {
    background: #1b4128;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    padding: 6px 20px;
    color: #fff;
}

.bannerContent .title p {
    color: #626262;
}

.bannerContent .title h3 {
    font-size: 40px;
    text-transform: capitalize;
    color: #131011;
}

/* .percentOff {
    display: flex;
    align-items: center;
    column-gap: 20px;
} */
.bannerContent .title h1 {
    color: #fff;
    font-size: 80px;
    text-transform: uppercase;
    line-height: 60px;
    margin-bottom: 40px;

}

.bannerContent .priceSection {
    display: flex;
    align-items: center;
}

.bannerContent .priceSection .text {
    color: rgb(160, 160, 160);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}

.bannerContent .priceSection .number {
    padding: 0 8px;
    color: #fb641b;
    font-weight: bold;
    font-size: 24px;
}

.shopNow a {
    display: inline-block;
    background-color: #2e2c2d;
    color: #fff;
    padding: 10px 26px;
    text-transform: uppercase;
}

@media (max-width: 1100px) {
    .bannerText {
        width: 90%;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
    }

    .bannerText h2 {
        font-size: 60px;
        line-height: 50px;
    }
}

@media (max-width: 767px) {
    .bannerItem {
        z-index: -1;
    }

    .bannerText h2 {
        font-size: 50px;
        line-height: 50px;
    }


}

@media (max-width: 480px) {
    .bannerText h2 {
        font-size: 30px;
        line-height: 30px;
    }

    .bannerText p {
        display: none;
    }
}