.serviceItemInfo {
    display: flex;
    justify-content: space-between;
}

.serviceItemIcon {
    position: relative;
    padding: 0 2px;
}

.serviceItemText {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
}

.serviceItemIcon svg {
    font-size: 40px;
    color: #37b74d;
}

.serviceItemIcon:hover img {
    transform: rotateY(180deg);
}

.serviceItemIcon img {
    transition: .4s ease-in-out;
}

.serviceItemIcon .rupee {
    color: #fb9678;
}

.serviceItemIcon .wallet {
    color: #e95454;
}

.serviceItemIcon .mobile {
    color: #fb641b;
}

.serviceItemIcon .offer {
    color: #b9bb41;
}

.serviceItemText h5 {
    margin: 0;
    text-align: center;
}

.serviceItemText p {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
}

.mediaQuaryClas {
    position: relative;
}

.serviceItemText a {
    color: #1a1b39;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 10px;
    background-color: #fff;
    transition: .4s ease-in-out;
}

.serviceItemIcon:hover+.serviceItemText a {
    color: #1a1b39;
    background-color: #ececec;
}



@media (max-width: 991px) {
    .serviceItem {
        border: 0;
    }

    .categoryBody {
        justify-content: space-between;
        column-gap: 10px;
    }

    .categoryBody .singleProducts {
        width: 45%;
    }

    .serviceItemInfo {
        flex-wrap: wrap;
        justify-content: center;
    }


    .categoryHeader a {
        padding: 2px 6px;
    }
}

@media (max-width: 767px) {
    .featuredContent h5 {
        text-transform: capitalize;
        font-size: 18px;
        height: 20px;
    }

    .featuredImg img {
        height: 100% !important;
    }

    .serviceItem {
        border: 0 !important;
    }

    .serviceItemText a {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .serviceItem {
        width: 100%;
    }
}