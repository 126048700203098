.brandsItem ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.brandsItem ul li a {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100px;
    padding: 10px;
    width: 140px;
    margin-bottom: 15px;
}