.breadcrumbSec{
    padding: 10px 0;
    background-color: #f3f4f7;
}
.breadcrumbSec .breadcrumb{
    margin: 0;
    padding: 0;
}
.breadcrumb-item a {
    color: #3e5169;
}